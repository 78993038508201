@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..600;1,300..600&display=swap');

$backdrop: #eeeff2;
$background: #ffffff;
$border: #dadce0;
$primary: #364950;
$dark: #dadce06b;

@import 'bootstrap/scss/bootstrap';

* {
    font-family: 'Open Sans', sans-serif !important;
}

body {
    background-color: $backdrop;
}

#root {
    position: relative;
    &>section {
        min-height: calc(100vh - 3.375rem);
        margin-top: 3.375rem;
    }
}

.mobile_screen {
    max-width: 48.125rem;
    margin: auto;
}

.card {
    border-color: $border;
    border-radius: .5rem;
}

.card-body {
    padding: 1.5rem;
}

.text-justify {
    text-align: justify;
}

.fw-bold {
    font-weight: bold;
}

.navbar-toggler {
    padding: var(--bs-navbar-toggler-padding-y);
    border: none;
}

.loader {
    width: 35%;
    max-width: 7.5rem;
    animation: redupNyala 1s infinite alternate;
}

.loading_indicator {
    position: fixed;
    top: -4.5rem;
    left: calc(50% - 1.25rem);
    right: calc(50% - 1.25rem);
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $background;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    z-index: 99;
    transition: 1s;

    &.open {
        top: 4.5rem;
    }
}

@keyframes redupNyala {
    0% {
        opacity: 0.3;
    }
    100% {
        opacity: 1;
    }
}

form {
    .form-control, .form-select {
        padding-left: 0;
        border: none;
        border-bottom: 1px solid $border;
        border-radius: 0;
        
        &:focus {
            box-shadow: unset !important;
        }

        &:focus + .separator {
            transform: scaleX(1) translateY(-2px);   
            opacity: 1;
        }
    }

    .form-control-check {
        .form-control {
            color: transparent;
            &:valid, &:invalid {
                border-bottom: none;
            }
        }
    }

    .was-validated {
        .form-control {
            &:not(.form-control-check) {
                &:valid + .separator {
                    background: $success;
                }
                &:invalid + .separator {
                    background: $danger;
                }
            }

            &:valid:not(:focus) {
                border-color: $border !important;
            }
        }
    }
}

.registration {
    @extend .mobile_screen;

    > form {
        position: relative;
        padding: 1.5rem 1rem 4.75rem;
        min-height: inherit;
    }

    &_progress {
        position: fixed;
        top: 3.375rem;
        left: 0;
        width: 100%;
        border-radius: unset;
        height: calc(var(--bs-progress-height) - 0.5rem);
        z-index: 99;
    }

    &_footer {
        display: flex;
        gap: 0.5rem;
        position: absolute;
        width: calc(100% - 2rem);
        bottom: 1rem;

        .btn {
            width: 100%;
            margin-top: 1.5rem;
            padding-top: .75rem !important;
            padding-bottom: .75rem !important;
        }
    }

    .btn:not(.btn-sm) {
        border-radius: 4px;
        padding: 0.45rem 1.5rem;
    }

    .child_form_action {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-top: 1.5rem;
        margin-bottom: 1rem;

        span {
            display: block;
        }
    }

    .child_form_label {
        display: flex;
        position: relative;
        height: 4rem;
    
        span {
            position: absolute;
            left: 0;
            top: 25%;
            background-color: $backdrop;
            padding-right: 1rem;
            z-index: 2;
            text-transform: capitalize;
        }
    
        button {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 15%;
            right: 0;
            width: 2.5rem;
            height: 2.5rem;
            background-color: $danger;
            border-radius: 0.3rem;
            border: none;
            color: #ffffff;
            &:not(:hover) {
                opacity: .75;
            }
        }
    
        &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            height: 1px;
            width: calc(100% - 3rem);
            background-color: #b1b1b1;
            z-index: 1;
        }
    }
    
    .separator {
        height: 1.5px;
        width: 100%;
        background: $primary;
        display: block;
        transform: scaleX(0) translateY(-2px);
        transform-origin: 50%;
        opacity: 0;
        transition: all .15s linear;
    }
}

.transaction_container {
    @extend .mobile_screen;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 1rem;

    .transaction_cover {
        display: flex;
        align-items: center;
        img {
            max-width: 10rem;
            margin-left: -4rem;
        }

        &_label {
            flex: 1;
            padding: 1rem;
            background-color: $primary;
            border-radius: 0.75rem;

            h6 {
                color: $background;
            }
        }

        &_label_description {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;

            p {
                width: calc(100% - 3rem);
                max-width: 20rem;
                font-size: small;
                color: $border;
                margin-bottom: 0;
            }
        }

        &_button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2.5rem;
            height: 2.5rem;
            background-color: $background;
            border: none;
            border-radius: 50%;

            svg {
                fill: $primary;
            }
        }
    }

    .transaction_content_label {
        display: block;
        margin: 1.5rem 0 0.5rem 1rem
    }

    .transaction_content_item {
        padding: 1rem;
        transition: .2s;
        cursor: pointer;
        background-color: $background;
        &:first-child {
            border-top-left-radius: 0.75rem;
            border-top-right-radius: 0.75rem;
        }
        &:last-child {
            border-bottom-left-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
        }
        &:not(:last-child) {
            border-bottom: 1px solid $dark;
        }
        &:hover {
            opacity: 0.6;
        }
    }
}

.transaction_detail_container {
    padding: 1rem;
}

.transaction_pickup_container {
    @extend .mobile_screen;

    form {
        padding: 0 1rem 4.75rem;
    }

    .transaction_pickup_container_header {
        display: flex;
        align-items: center;
        padding: 1rem;

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2.5rem;
            height: 2.5rem;
            background-color: $background;
            border: none;
            border-radius: .25rem;
        }
    }
    
    .card {
        position: relative;
        overflow: hidden;

        .pickup_status {
            display: flex;
            gap: 0.25rem;
            align-items: center;
            position: absolute;
            top: 1.5rem;
            right: 1rem;
            padding: 0.25rem 0.5rem;
            z-index: 99;
            background-color: white;
            border: 1px solid $border;
            border-radius: 5rem;
            font-size: 0.6rem;

            > span {
                display: block;
            }

            > span:first-child {
                width: 0.6rem;
                height: 0.6rem;
                border-radius: 50%;
            }
        }
    }

    button {
        padding-top: .75rem !important;
        padding-bottom: .75rem !important;
        font-size: small;
        text-wrap: nowrap;
    }
}

.report_container {
    width: 100%;
    min-height: 100vh;
    padding: 1rem;
    background-color: $background;

    &_content {
        width: inherit;
        max-width: 125rem;
        margin: auto;
        padding: 1rem;
    }

    .report_filter {
        display: flex;
        justify-content: flex-end;
        gap: .5rem;
        width: 100%;
        margin: 1rem 0;

        input, select, button {
            padding-top: .7rem;
            padding-bottom: .7rem;
            font-size: .75rem;
        }

        input {
            padding-left: 2.25rem;
            padding-right: .5rem;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23000000'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'%3E%3C/path%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'%3E%3C/path%3E%3C/svg%3E"); /* Replace with the provided SVG code */
            background-repeat: no-repeat;
            background-size: 1rem;
            background-position: .75rem center;
        }

        .dropdown {

            .dropdown-toggle {
                height: 100%;
                padding-left: 2.25rem;
                background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="%23FFFFFF"%3E%3Cg%3E%3Crect fill="none" height="24" width="24"%3E%3C/rect%3E%3Cpath d="M14.17,5L19,9.83V19H5V5L14.17,5L14.17,5 M14.17,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V9.83 c0-0.53-0.21-1.04-0.59-1.41l-4.83-4.83C15.21,3.21,14.7,3,14.17,3L14.17,3z M7,15h10v2H7V15z M7,11h10v2H7V11z M7,7h7v2H7V7z"%3E%3C/path%3E%3C/g%3E%3C/svg%3E'); /* Replace with the provided SVG code */
                background-repeat: no-repeat;
                background-size: 1rem;
                background-position: .75rem center;
            }

            .dropdown-item-text {
                position: relative;
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;

                & > a {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }
                
                &:hover {
                    background-color: $backdrop;
                }
            }
        }
        
        button {
            padding-left: 2.25rem;
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="%23FFFFFF"%3E%3Cg%3E%3Crect fill="none" height="24" width="24"%3E%3C/rect%3E%3Cpath d="M14.17,5L19,9.83V19H5V5L14.17,5L14.17,5 M14.17,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V9.83 c0-0.53-0.21-1.04-0.59-1.41l-4.83-4.83C15.21,3.21,14.7,3,14.17,3L14.17,3z M7,15h10v2H7V15z M7,11h10v2H7V11z M7,7h7v2H7V7z"%3E%3C/path%3E%3C/g%3E%3C/svg%3E'); /* Replace with the provided SVG code */
            background-repeat: no-repeat;
            background-size: 1rem;
            background-position: .75rem center;
            position: relative;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;

            & > a {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }
        }
    }

    .report_children {
        margin: 1rem 0 1rem 3rem;
        &_payment {
            display: flex;
            flex-wrap: wrap;
            gap: calc(2rem / 3);
            > div {
                width: calc(25% - .5rem);
            }
        }
        &_participant {
            padding: .5rem;
            border: 1px solid $border;
            border-radius: .25rem;
        }
        &_partnership {
            flex: 1;
        }
        &_img {
            width: 10rem;
            min-height: 7.5rem;
            border-radius: 0.25rem;
            background-color: $dark;
        }
        span {
            display: block;
            font-size: .8rem;
        }
    }

    @mixin icon-styling() {
        position: relative;
        padding-left: 1.5rem;
        &::before {
            content: '';
            position: absolute;
            top: 1px;
            left: 0;
            width: 1rem;
            height: 1rem;
            border-width: .25rem;
            border-style: solid;
            border-radius: 50%;
        }
    }

    .is_join_cg_warning > div,
    .pick_up_type_warning > div {
        @include icon-styling;
        &::before {
            border-color: $warning;
        }
    }
    
    .is_join_cg_danger > div,
    .pick_up_type_danger > div {
        @include icon-styling;
        &::before {
            border-color: $danger;
        }
    }

    .is_join_cg_success > div,
    .pick_up_type_success > div {
        @include icon-styling;
        &::before {
            border-color: $success;
        }
    }

    .report_table_btn {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.25rem 0.25rem 0.25rem 0.75rem;
        background-color: $primary;
        color: white;
        border: none;
        border-radius: 0.25rem;
        font-size: smaller;
        text-wrap: nowrap;

        svg {
            transform: rotate(180deg);
            margin-bottom: -0.3rem;
        }
    }
}

.preview {
    @extend .mobile_screen;
    padding-bottom: 1rem;

    .alert .btn {
        position: absolute;
        right: 0.75rem;
        bottom: 0.75rem;
        letter-spacing: 1px;
    }

    &_info_image_preview {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        align-items: center;
        padding: 1rem;
        margin-bottom: 1rem;
        background-color: $dark;
        border-radius: 0.5rem;

        img {
            max-width: 25rem;
            max-height: 25rem;
            width: 100%;
            border-radius: 0.25rem;
        }
    }

    &_item {
        margin-bottom: 1rem;
    
        &_value {
            font-weight: bold;
        }
    }

    &_collapse {
        padding: 0.75rem;
        padding-bottom: 0;
        margin-bottom: 0.5rem;
        border: 1px solid #bebebe;
        border-radius: 0.375rem;
        
        .mycollapse_toggle {
            position: relative;
            svg {
                transition: 0.1s transform;
                position: absolute;
                top: 1rem;
                right: 0;
            }

            &[aria-expanded="true"] {
                svg {
                    transform: rotate(90deg);
                }
            }
        }

        .collapse {
            margin-top: 1rem;
        }
    }
    
    &_footer {
        position: fixed;
        width: 100%;
        left: 0;
        bottom: 0;
        padding: 0.5rem 1rem;
        background-color: $background;
        border-top: 1px solid $border;
        text-align: center;

        .btn {
            width: 100%;
            max-width: 48.125rem;
            padding-top: .75rem !important;
            padding-bottom: .75rem !important;
        }
    }

    span {
        display: block;
    }
}

.fill_space {
    flex: 1;
    background-color: $background;
}

.progress_placeholder {
    overflow: hidden;
    position: relative;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: placeholderAnimate;
    background: $backdrop;
    background: linear-gradient(to right, $backdrop 2%, $background 18%, $backdrop 33%);
    background-size: 1300px;
    
    &_item {
        width: 100%;
        height: 20px;
        position: absolute;
        z-index: 2;
        
        &:after,
        &:before {
            width: inherit;
            height: inherit;
            content: '';
            position: absolute;
        }
        
        &:nth-child(1) {
            top: 0;
            left: 0;
        }
    }

    &_child {
        position: absolute;
        top: 0;
        left: 0;
        width: inherit;
        height: inherit;
    }
}

.version_tag {
    position: fixed;
    bottom: 0;
    right: 0.25rem;
    font-weight: bold;
    font-size: small;
    z-index: 99;
}

@keyframes placeholderAnimate {
    0%{ background-position: -468px 0; }
    100%{ background-position: 468px 0; }
}

@media only screen and (min-width: 992px) {
    .navbar-collapse {
        flex-grow: unset;
    }
}

@media only screen and (max-width: 991px) {
    .navbar-nav {
        padding-top: .5rem;
    }

    .nav-link {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}

@media only screen and (min-width: 601px) {
    .registration {
        &_footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .report_container {
        .report_filter {
            input, select {
                width: 36%;
                max-width: 17.5rem;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .report_container {
        .report_filter {
            flex-direction: column;
            input, select {
                width: 100%;
            }
        }
    }
}